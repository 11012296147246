<template>
  <div class="app-container">
    <div class="filter-container" style="margin-bottom: 10px">
      <el-input v-model="listQuery.csid" placeholder="客服编号" style="width: 200px;" class="filter-item" @keyup.enter.native="handleFilter" />
      <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleFilter">
        查询
      </el-button>
    </div>

    <el-table
      :data="list"
      style="width: 100%;margin-bottom: 20px;"
      row-key="id"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column
        v-for="(item,index) in tableList"
        :key="index + 's'"
        :label="item.label"
        :prop="item.prop"
        :width="item.width"
      ></el-table-column>
    </el-table>
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
  </div>
</template>

<script>
  import {
    getQuickreply
  } from "@/apis/quick-reply";
  export default {
    name: 'quicklinks',
    components: {
      pagination: () => import('@/components/common/PagingPlug'),
    },
    data() {
      return {
        tableList: [
          {
            label: "客服编号",
            prop: "csid",
            width:"80"
          },
          {
            label: "客服名称",
            prop: "account",
            width:"100"
          },
          {
            label: "快捷回复分组",
            prop: "date",
            width:"150"
          },
          {
            label: "标题",
            prop: "title",
            width:"200"
          },
          {
            label: "回复内容",
            prop: "content"
          },
          {
            label: "创建时间",
            prop: "createtime",
            width:"160"
          },
          {
            label: "修改时间",
            prop: "updatetime",
            width:"160"
          }
        ],
        list: [],
        total: 0,
        listLoading: true,
        listQuery: {
          page: 1,
          limit: 20,
          csid:null,
          auid:null
        },
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList() {
        this.listLoading = true
        this.listQuery.auid=this.user.userId
        getQuickreply(this.listQuery).then(res => {
        // const {count = 0} = res;
            this.list= res.retdata.quickReplyList;
            this.total= 0;
            this.listLoading = false;
        })
      }, handleFilter() {
        this.listQuery.page = 1
        this.getList()
      },
    }
  }
</script>

<style scoped>
.filter-item {
  margin-left:5px;
}

</style>
