/**
 * 回复操作js
 */
import axios from "@/resources/js/request";
import da from "element-ui/src/locale/lang/da";
const request = axios.request;
/**
 * 初始化页面快捷回复数据
 * @returns {Promise<unknown>}
 */
export const getQuickreply = (data) => {
  return request.doPost('/quickreply/getQuickreply', data);
}

/**
 * 添加快捷回复分组
 * @returns {Promise<unknown>}
 */
export const addorupdategroup = (data) => {
  return request.doPost('/quickreply/addorupdategroup', data);
}
/**
 * 添加或者修改快捷内容
 * @returns {Promise<unknown>}
 */
export const addorupdateQuickreplyInfo = (data) => {
  return request.doPost('/quickreply/addorupdateQuickreplyInfo', data);
}
/**
 * 删除分组
 * @returns {Promise<unknown>}
 */
export const deleteGroup = (data) => {
  return request.doPost('/quickreply/deleteGroup', data);
}
/**
 * 删除快捷内容
 * @returns {Promise<unknown>}
 */
export const deleteQuickreplyInfo = (data) => {
  return request.doPost('/quickreply/deleteQuickreplyInfo', data);
}

